<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" v-if="renderComp" />
    <v-row class="mt-5 mx-12">
      <v-col cols="12" md="12" sm="12" xs="12" align="center">
        <!-- <h2 class="mt-n4 font-weight-regular">
          Congratulations! Your credentials have been verified. Kindly go ahead
          and provide your Organization Details
        </h2> -->
      </v-col>
      <v-col cols="12" md="7" sm="7" xs="12">
        <v-card class="elevation-0 mt-12 ml-10" height="300px" width="600px">
          <v-img src="@/assets/letter1.jpg"></v-img>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" sm="5" xs="12">
        <v-row class="d-flex justify-center">
          <v-col cols="4" md="4" sm="4" xs="12">
            <v-img src="@/assets/RL.png" align="center"></v-img>
          </v-col>
        </v-row>
        <h2 align="center">Organization Details</h2>
        <v-form ref="orgDetailsForm">
          <v-text-field
            dense
            outlined
            label="Organization Address"
            v-model="org_add"
            prepend-inner-icon="mdi-map-marker"
            :rules="[(v) => !!v || 'Required']"></v-text-field>
          <v-select
            dense
            outlined
            label="Organization Type"
            :items="selectOrgType"
            v-model="issuer_type"
            prepend-inner-icon="mdi-domain"
            :rules="[(v) => !!v || 'Required']"></v-select>
          <v-select
            dense
            outlined
            label="Document Type"
            v-model="doc_type"
            :items="selectDocType"
            prepend-inner-icon="mdi-text-box-search"
            :rules="[(v) => !!v || 'Required']"></v-select>
          <v-text-field
            dense
            outlined
            label="Document id"
            v-model="doc_id"
            prepend-inner-icon="mdi-account-key"
            :rules="[(v) => !!v || 'Required']"></v-text-field>
          <v-file-input
            prepend-icon=""
            dense
            outlined
            label="Upload Document"
            v-model="doc_auth"
            @change="uploadDocumentAuth"
            prepend-inner-icon="mdi-text-box-multiple-outline"
            hint="File size should be less than 5 MB"
            :rules="[(v) => !!v || 'Required']"></v-file-input>
        </v-form>
        <!-- <v-card-subtitle v-if="validDoc == true" class="mt-n10 grey--text"
          >Authentication document already uploaded</v-card-subtitle
        > -->
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="3" class="font-weight-bold">Upload Logo</v-col>
          <v-col cols="12" xs="12" sm="12" md="9" align="left">
            <v-card outlined @click="checkImagePresent()" width="200px" height="100px" align="center" class="overflow-hidden">
              <v-fab-transition v-if="showLogo != null && showLogo != ''">
                <v-btn
                  absolute
                  top
                  right
                  dark
                  x-small
                  fab
                  color="red"
                  class="mt-4"
                  @click.stop="(showLogo = null), (uploadedImage = false), s3DeleteMethod()">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-img v-if="showLogo != null && showLogo != ''" :src="showLogo" :lazy-src="showLogo" max-width="300px" class="mt-1"></v-img>
              <div v-else>
                <input ref="excel-upload-input" accept="image/*" class="excel-upload-input" type="file" @change="uploadorglogo" />
                <v-icon class="mt-6">mdi-upload</v-icon>
                <div align="center ">Upload Logo</div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-card-actions class="d-flex justify-center">
          <!-- <v-btn
            class="mainbutton white--text"
            style="font-size: 13px"
            @click="$router.push('/LandingPage')"
            width="50%"
            >Skip</v-btn
          > -->
          <v-btn class="mainbutton white--text" style="font-size: 13px" :loading="loadingOrgDetails" @click="validateFormMethod" width="100%"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Buffer } from "buffer";
var AWS = require("aws-sdk");
// import axios from "axios";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { RLPostSignUp } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    step: 1,
    org_add: "",
    org_Logo: null,
    doc_type: "",
    doc_id: "",
    doc_auth: null,
    showLogo: "",
    viewLogo: false,
    showDoc: "",
    viewAuthDoc: false,
    selectOrgType: ["Business", "Non-Profit", "Association"],
    selectDocType: ["PAN Number", "GST Number", "CIN Number"],
    heightPDF: "",
    orgLogoUrl: "",
    orgAuthDocUrl: "",
    Email: "",
    issuerId: "",
    issuer_type: "",
    SnackBarComponent: {},
    loadingOrgDetails: false,
    renderComp: true,
    detailsObj: {},
    validDoc: false,
  }),
  mounted() {
    // this.heightPDF = window.innerHeight - 100;
    this.Email = this.$store.getters.get_user_email;
    // this.getIssuerId();
  },
  watch: {},
  methods: {
    checkImagePresent() {
      if (this.showLogo == null || this.showLogo == "" || this.showLogo == undefined) {
        this.handleUpload();
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    goTostep2() {
      if (this.$refs.form1.validate()) {
        this.step = 2;
      } else {
        this.renderComp = false;
        this.$nextTick(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: "Field Cannot be empty",
            timeout: 5000,
            Top: true,
          };
          this.renderComp = true;
        });
      }
    },
    // async getIssuerId() {
    //   let result = await axios.post(
    //     "https://pe5at1eac8.execute-api.ap-south-1.amazonaws.com/dev/getorganizationdetailsfromemailid",
    //     {
    //       contact_email_id: this.$store.getters.get_user_email,
    //     }
    //   );
    //   console.log(this.Email);
    //   this.issuerId = result.data.data.Items[0].issuer_id;
    //   console.log(result.data.data.Items[0]);
    //   if (result.data.data.Items[0].issuer_address_1 != "") {
    //     this.org_add = result.data.data.Items[0].issuer_address_1;
    //   }
    //   if (result.data.data.Items[0].issuer_type != "") {
    //     this.issuer_type = result.data.data.Items[0].issuer_type;
    //   }
    //   if (result.data.data.Items[0].account_logo_url != "") {
    //     this.showLogo = result.data.data.Items[0].account_logo_url;
    //     this.org_Logo = "Something";
    //   }
    //   if (result.data.data.Items[0].identification_type != "") {
    //     this.doc_type = result.data.data.Items[0].identification_type;
    //   }
    //   if (result.data.data.Items[0].identification_number != "") {
    //     this.doc_id = result.data.data.Items[0].identification_number;
    //   }
    //   if (result.data.data.Items[0].document_url != "") {
    //     this.validDoc = true;
    //   }
    // },
    async uploadorglogo(e) {
      var s3Bucket = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: "AKIARIY6IPQHZI6O3QVI",
        secretAccessKey: "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",
      });
      var self = this;
      const currentDate = new Date();
      let epoch1 = currentDate.getTime();
      this.org_Logo = e.target.files[0];
      var fileReader = new FileReader();
      fileReader.readAsDataURL(this.org_Logo);
      fileReader.onload = async function (val) {
        self.showLogo = val.target.result;
        self.viewLogo = true;
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket: "workhistory-mumbai",
          Key: `${self.issuerId}/account_logo/${epoch1}_${self.org_Logo.name}`,
          Body: buf,
          ContentType: self.org_Logo.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            console.log(err);
            self.loading = false;
          } else if (data) {
            let brand_logo = `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${param.Key}`;
            self.orgLogoUrl = brand_logo;
            console.log(brand_logo);
          }
        });
      };
    },
    async uploadDocumentAuth() {
      const currentDate = new Date();
      let epoch2 = currentDate.getTime();
      var s3Bucket = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: "AKIARIY6IPQHZI6O3QVI",
        secretAccessKey: "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",
      });
      var self = this;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(this.doc_auth);
      fileReader.onload = async function (val) {
        self.showDoc = val.target.result;
        self.viewAuthDoc = true;
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        console.log(buf);
        var param = {
          Bucket: "workhistory-mumbai",
          Key: `${self.issuerId}/document_url/${epoch2}_${self.doc_auth.name}`,
          Body: buf,
          ContentType: self.doc_auth.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            console.log(err);
            self.loading = false;
          } else if (data) {
            let brand_logo = `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${param.Key}`;
            self.orgAuthDocUrl = brand_logo;
            console.log(brand_logo);
          }
        });
      };
    },
    async addDetailsOfOrg() {
      let inputParams = {
        contact_email_id: this.$store.getters.get_user_email,
        account_logo_url: this.orgLogoUrl,
        issuer_address_1: this.org_add,
        issuer_type: this.issuer_type,
        identification_type: this.doc_type,
        identification_number: this.doc_id,
        document_url: this.orgAuthDocUrl,
      };
      console.log(inputParams);
      this.loadingOrgDetails = true;
      let result = await API.graphql(
        graphqlOperation(RLPostSignUp, {
          input: inputParams,
        })
      );
      console.log(result);
      let resultObject = JSON.parse(result.data.RLPostSignUp);
      // let url =
      //   "https://pe5at1eac8.execute-api.ap-south-1.amazonaws.com/dev/postsignup";
      // let result = await axios.post(url, obj);
      this.loadingOrgDetails = false;
      if (resultObject.status == "SUCCESS") {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: resultObject.status_message,
        };
        this.$router.push("/LandingPage");
      } else if (result.data.errorType == "Error") {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "All the necessary fields have been added for the user",
        };
      }
    },
    validateFormMethod() {
      if (this.$refs.orgDetailsForm.validate()) {
        this.addDetailsOfOrg();
      } else {
        this.renderComp = false;
        this.$nextTick(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Fields marked with asterisk(*) are mandatory",
          };
          this.renderComp = true;
        });
      }
    },
  },
};
</script>

<style>
.signInCard {
  display: grid;
  place-items: center;
  height: 80vh;
  margin: 0;
}
</style>
