<template>
  <v-app>
    <!-- <v-app-bar elevation="1" app color="white">
      <v-card max-width="190px" class="elevation-0">
        <v-img src="@/assets/SureCredsLogo.png"></v-img>
      </v-card>
      <v-divider vertical class="mx-3"></v-divider>
      <div class="largeFontSizePrimaryGreen font-weight-bold">Register With SureCreds</div>
      <v-spacer></v-spacer>
    </v-app-bar> -->
    <div>
      <Snackbar :SnackBarComponent="SnackBarComponent" v-if="renderComp" />
      <!-- For Mobile -->
      <v-card v-show="$vuetify.breakpoint.name == 'xs'" elevation="0" dense>
        <v-row>
          <!-- <v-col cols="1" md="1" sm="12" xs="12"></v-col> -->
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-card dense elevation="0" class="ma-2">
              <v-img src="@/assets/Rl_Log_new.svg" class="image"></v-img>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="12"
            class="d-flex justify-center"
            sm="12"
            xs="12"
          >
            <v-card width="500" elevation="0">
              <v-row>
                <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                  <!-- <span class="ml-5"> Already a user? </span>
                  <span
                    class="
                      body-2
                      mt-n3
                      green--text
                      text-decoration-underlin
                      font-weight-medium
                    "
                    @click="$router.push('/Login')"
                    style="cursor: pointer"
                    >Sign In</span
                  > -->
                </v-col>
              </v-row>
              <v-card-text>
                <h3 align="center" class="font-weight-bold my-4">
                  Register your organization
                </h3>
                <v-form ref="form">
                  <v-text-field
                    outlined
                    dense
                    label="Organization Name*"
                    v-model="org_name"
                    class="mt-n2"
                    :rules="[
                      (v) => !!v || 'Required',
                      (v) =>
                        /^[A-Za-z\s]+$/.test(v) ||
                        'Only text characters are allowed',
                    ]"
                    prepend-inner-icon="mdi-domain"
                  ></v-text-field>
                  <!-- <v-text-field
                    outlined
                    dense
                    label="Organization Id"
                    v-model="org_id"
                    class="mt-n2"
                    :rules="[(v) => !!v || 'Required']"
                  ></v-text-field> -->
                  <v-select
                    outlined
                    dense
                    label="No of members*"
                    :items="members"
                    v-model="no_of_members"
                    class="mt-n2"
                    :rules="[(v) => !!v || 'Required']"
                    prepend-inner-icon="mdi-account-group"
                  ></v-select>
                  <h3 align="center" class="font-weight-bold">
                    Organization Contact Person
                  </h3>
                  <v-text-field
                    outlined
                    dense
                    label="Full Name*"
                    v-model="full_name"
                    :rules="[
                      (v) => !!v || 'Required',
                      (v) =>
                        /^[A-Za-z\s]+$/.test(v) ||
                        'Only text characters are allowed',
                    ]"
                    prepend-inner-icon="mdi-account-circle"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    label="Email Id*"
                    v-model="email_id"
                    class="mt-n2"
                    :rules="[
                      (v) => !!v || 'Required',
                      (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
                    ]"
                    prepend-inner-icon="mdi-email"
                  ></v-text-field>
                  <v-select
                    outlined
                    dense
                    label="Country"
                    v-model="country"
                    :items="select_country"
                    item-text="label"
                    item-value="value"
                    class="mt-n2"
                    :rules="[(v) => !!v || 'Required']"
                    prepend-inner-icon="mdi-map-marker"
                  ></v-select>
                  <v-row>
                    <v-col cols="5">
                      <v-text-field
                        dense
                        outlined
                        label="country_code"
                        v-model="country_code"
                        class="mt-n2"
                        :rules="[(v) => !!v || 'Required']"
                        prepend-inner-icon="mdi-map-marker-circle"
                        :readonly="true"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        dense
                        outlined
                        label="Phone Number*"
                        v-model="phone"
                        class="mt-n2"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) =>
                            /^\d{10}$/.test(v) ||
                            'Phone number must be 10 digits',
                        ]"
                        prepend-inner-icon="mdi-phone"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <!-- <span class="ml-5"> Already a user? </span>
              <span
                class="
                  body-2
                  mt-n3
                  green--text
                  text-decoration-underlin
                  font-weight-medium
                "
                @click="$router.push('/Login')"
                style="cursor: pointer"
                >Sign In</span
              > -->
              <!-- <v-row>
                <v-col cols="4" md="4"><span> Already a user? </span></v-col>
                <v-col cols="3" md="3">
                  <v-card-subtitle
                    class="
                      body-2
                      mt-n3
                      ml-n12
                      green--text
                      text-decoration-underlin
                      font-weight-medium
                    "
                    @click="$router.push('/Login')"
                    style="cursor: pointer"
                  >
                    Sign In
                  </v-card-subtitle>
                </v-col>
              </v-row> -->

              <v-card-actions class="d-flex justify-center mx-2 mt-n5">
                <v-btn
                  class="mainbutton white--text"
                  style="font-size: 13px"
                  @click="validateFormMethod"
                  :loading="loading"
                  width="100%"
                  >CONFIRM with OTP</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      <!-- For Laptop -->
      <v-card
        v-show="$vuetify.breakpoint.name != 'xs'"
        class="mx-10 mt-4 elevation-0"
      >
        <v-row>
          <v-col cols="1" md="1" sm="12" xs="12"></v-col>
          <v-col cols="5" md="5" class="mt-12" sm="12" xs="12">
             <!-- <v-img src="@/assets/Rl_Log_new.svg" class="image"></v-img>  -->
            <v-img max-width="500px" src="@/assets/Rl_Log_new.svg"></v-img>
          </v-col>
          <v-col cols="6" md="6" class="d-flex justify-center" sm="12" xs="12">
            <v-card width="500" elevation="0">
              <v-row class="d-flex justify-space-between">
              <v-col cols="4" md="4" sm="4" xs="4">
                  <!-- <v-img src="@/assets/RL.png" align="center"></v-img> -->
                </v-col>
                <v-col cols="5" md="5" sm="5" xs="5" class="ml-n10">
                  <!--   <span class="ml-5"> Already a user? </span>
                  <span
                    class="
                      body-2
                      mt-n3
                      green--text
                      text-decoration-underlin
                      font-weight-medium
                    "
                    @click="$router.push('/Login')"
                    style="cursor: pointer"
                    >Sign In</span-->
                </v-col>

                <v-col cols="1" md="1">
                  <a href="https://relievingletter.com/"
                    ><v-icon large color="green"
                      >mdi-arrow-left-circle</v-icon
                    ></a
                  >
                </v-col>
              </v-row>
              <h3 align="center" class="mt-3 fontColor mb-4">
                Register Organization
              </h3>
              <v-form ref="form">
                <v-text-field
                  outlined
                  dense
                  label="Organization Name*"
                  v-model="org_name"
                  class="mt-n2"
                  :rules="[(v) => !!v || 'Required']"
                  prepend-inner-icon="mdi-domain"
                ></v-text-field>
                <!-- <v-text-field
                    outlined
                    dense
                    label="Organization Id"
                    v-model="org_id"
                    class="mt-n2"
                    :rules="[(v) => !!v || 'Required']"
                  ></v-text-field> -->
                <v-select
                  outlined
                  dense
                  label="No of members*"
                  :items="members"
                  v-model="no_of_members"
                  class="mt-n2"
                  :rules="[(v) => !!v || 'Required']"
                  prepend-inner-icon="mdi-account-group"
                ></v-select>
                <h3 align="center" class="mt-n2 mb-3 fontColor">
                  Organization Contact Person
                </h3>
                <v-text-field
                  outlined
                  dense
                  label="Full Name*"
                  v-model="full_name"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) =>
                      /^[A-Za-z\s]+$/.test(v) ||
                      'Only text characters are allowed',
                  ]"
                  prepend-inner-icon="mdi-account-circle"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Email Id*"
                  v-model="email_id"
                  class="mt-n2"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
                  ]"
                  prepend-inner-icon="mdi-email"
                ></v-text-field>
                <v-select
                  outlined
                  dense
                  label="Country"
                  v-model="country"
                  :items="select_country"
                  item-text="label"
                  item-value="value"
                  class="mt-n2"
                  :rules="[(v) => !!v || 'Required']"
                  prepend-inner-icon="mdi-map-marker"
                ></v-select>
                <v-row>
                  <v-col cols="5">
                    <!-- <v-text-field
                        dense
                        outlined
                        label="country_code"
                        v-model="country_code"
                        class="mt-n2"
                        :rules="[(v) => !!v || 'Required']"
                        prepend-inner-icon="mdi-map-marker-circle"
                        :readonly="true"
                      ></v-text-field> -->
                    <v-select
                      outlined
                      dense
                      :items="CountryList"
                      item-value="dial_code"
                      item-text="name"
                      class="mt-n2"
                      label="Country Code"
                      v-model="issued_to_country_code"
                    >
                      <template slot="selection" slot-scope="{ item }">
                        {{ item.dial_code }}
                      </template>
                      <template slot="item" slot-scope="{ item }"
                        >{{ item.name }}({{ item.dial_code }})
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                      dense
                      outlined
                      label="Phone Number*"
                      v-model="phone"
                      class="mt-n2"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) =>
                          /^\d{10}$/.test(v) ||
                          'Phone number must be 10 digits',
                        (v) =>
                          /^([6789]\d{9})?$/.test(v) || 'Invalid phone number',
                      ]"
                      prepend-inner-icon="mdi-phone"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <!-- <span class="ml-5"> Already a user? </span>
              <span
                class="
                  body-2
                  mt-n3
                  green--text
                  text-decoration-underlin
                  font-weight-medium
                "
                @click="$router.push('/Login')"
                style="cursor: pointer"
                >Sign In</span
              > -->
              <!-- <v-row>
                <v-col cols="4" md="4"><span> Already a user? </span></v-col>
                <v-col cols="3" md="3">
                  <v-card-subtitle
                    class="
                      body-2
                      mt-n3
                      ml-n12
                      green--text
                      text-decoration-underlin
                      font-weight-medium
                    "
                    @click="$router.push('/Login')"
                    style="cursor: pointer"
                  >
                    Sign In
                  </v-card-subtitle>
                </v-col>
              </v-row> -->

              <v-card-actions class="d-flex justify-center">
                <v-btn
                  class="mainbutton white--text"
                  style="font-size: 13px"
                  @click="validateFormMethod"
                  :loading="loading"
                  width="100%"
                >
                  Free Trial
                </v-btn>
              </v-card-actions>

              <span class="d-flex justify-center"
                >Already a user
                <span
                  class="green--text ml-2"
                  @click="$router.push('/Login')"
                  style="cursor: pointer"
                >
                  Sign In
                </span>
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-app>
</template>
<script>
// import { GetAllCountriesRESTAPI } from "@/mixins/GetAllCountriesRESTAPI.js";
// import { GetAllIssuerTypeRESTAPI } from "@/mixins/GetAllIssuerTypeRESTAPI.js";
// import { GetAllCountryCityStates } from "@/mixins/GetAllCountryCityStates.js";
import { API } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import axios from "axios";
import CountryList from "@/JsonFiles/CountryList.json";
// import IssuerOnboardedSuccess from "@/components/OnBoardIssuer/Dialogs/IssuerOnboardedSuccess.vue";
export default {
  props: {
    dialogCreateIssuerUser: Boolean,
  },
  mixins: [
    // GetAllCountryCityStates,
    // GetAllCountriesRESTAPI,
    // GetAllIssuerTypeRESTAPI,
  ],
  components: {
    Snackbar,
    // IssuerOnboardedSuccess,
  },
  created() {
    this.fetchCountries();
  },
  data: () => ({
    org_name: "",
    // org_id: "",
    no_of_members: "",
    members: ["0-10", "10-100", "100-200"],
    full_name: "",
    email_id: "",
    country: "+91",
    select_country: [],
    country_code: "+91",
    country_code_select: ["+91"],
    phone: "",
    loading: false,
    imgSrc: null,
    height: 0,
    search: null,
    current_view: "CREATE",
    modify_bool: false,
    uploadedImage: false,
    selectedFile: null,
    SnackBarComponent: {},
    create_issuer: {
      issuer_name: "",
      issuer_type: "",
      identification_type: "",
      identification_number: "",
      issuer_address_1: "",
      issuer_address_2: "",
      country: "",
      state: "",
      city: "",
      district: "",
      postal_code: "",
      contact_email_id: "",
      contact_user_name: "",
      country_code: "",
      contact_number: "",
      temporary_password: "",
      enable_verification_requests: false,
      issuer_verification_fee: 0,
      currency_type: "",
    },
    typeOfBusinessItems: [
      "issuer",
      "Limited Liability Partnership",
      "Sole Proprietorship",
      "Individual",
    ],
    stateList: [],
    citiesList: [],
    identificationTypeItems: [],
    GetAllCountriesList: [],
    currencyItems: ["INR", "DOLLAR"],
    IssuerOnboardedSuccessDialog: false,
    renderComp: true,
    countryOptions: "",
    CountryList: [],
    issued_to_country_code: "",
  }),
  watch: {
    "create_issuer.enable_verification_requests"(val) {
      if (val == false) {
        this.create_issuer.issuer_verification_fee = 0;
      }
    },
    "create_issuer.country"(val) {
      if (val != undefined && val != null && val != "") {
        this.identificationTypeItems = this.GetAllCountriesList.filter(
          (item) => item.country_name == val
        )[0].identification_types;
        this.getAllStatesMethod(val);
        this.create_issuer.state = "";
        this.create_issuer.city = "";
        this.create_issuer.currency_type = this.countryList.filter(
          (country) => country.name == val
        )[0].currency;
      }
    },
    "create_issuer.state"(val) {
      if (
        val != null &&
        val != undefined &&
        val != "" &&
        this.GetAllCountriesList.length != 0
      ) {
        this.getAllCitiesMethod(val);
      }
    },
  },
  mounted() {
    // this.GetAllCountriesMethod();
    // this.getAllCountriesMethod();
    // this.GetAllIssuerTypeMethod();
    this.CountryList = CountryList;
    this.issued_to_country_code = "+91";
  },
  methods: {
    fetchCountries() {
      // var countryccWithIso = [];
      var countryWithCc = [];
      this.countriesData = [];
      CountryList.forEach((element) => {
        countryWithCc.push({
          label: element.name,
          value: element.dial_code,
        });
        this.countriesData.push(element.name);
      });

      var countryListt = countryWithCc;
      this.select_country = countryListt;
    },
    async confirm() {
      let country = "";
      if (this.country == "+91") {
        country = "India";
      }
      this.loading = true;
      let result = await axios.post(
        "https://pe5at1eac8.execute-api.ap-south-1.amazonaws.com/dev/signuporganization",
        {
          command: "signupOrganization",
          contact_email_id: this.email_id,
          issuer_name: this.org_name,
          number_of_members: this.no_of_members,
          full_name: this.full_name,
          country_code: this.issued_to_country_code,
          country: country,
          contact_number: this.phone,
        }
      );
      this.loading = false;
      if (result.data.status == "SUCCESS") {
        this.renderComp = false;
        this.$nextTick(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: result.data.status_message,
            timeout: 4000,
          };
          this.renderComp = true;
        });
        this.$store.commit("SET_USEREMAIL", this.email_id);
        setTimeout(() => {
          this.$router.push("/otpPage");
        }, 1000);
      } else if (result.data.errorType == "UsernameExistsException") {
        this.renderComp = false;
        this.$nextTick(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: result.data.errorMessage,
          };
          this.renderComp = true;
        });
      }
    },
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.confirm();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisk(*) are mandatory",
        };
      }
    },
    async createIssuerMethod() {
      this.loading = true;
      const path = "onboardissuer";
      const myInit = {
        body: {
          command: "createIssuer",
          city: this.create_issuer.city,
          contact_email_id: this.create_issuer.contact_email_id,
          contact_number: this.create_issuer.contact_number,
          contact_user_name: this.create_issuer.contact_user_name,
          country: this.create_issuer.country,
          country_code: this.create_issuer.country_code,
          currency_type: this.create_issuer.currency_type,
          district: this.create_issuer.district,
          enable_verification_requests:
            this.create_issuer.enable_verification_requests,
          identification_number: this.create_issuer.identification_number,
          identification_type: this.create_issuer.identification_type,
          issuer_address_1: this.create_issuer.issuer_address_1,
          issuer_address_2: this.create_issuer.issuer_address_2,
          issuer_name: this.create_issuer.issuer_name,
          issuer_type: this.create_issuer.issuer_type,
          issuer_verification_fee: this.create_issuer.issuer_verification_fee,
          postal_code: this.create_issuer.postal_code,
          state: this.create_issuer.state,
          partner_id: "PTNR-1",
        },
      };
      API.post("SURECREDS", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            if (response.status == "Success") {
              this.IssuerOnboardedSuccessDialog = true;
            }
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: response.errorMessage,
            };
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.image {
  /* Common styles for all screen sizes */
  width: 100%;
  height: auto;

  /* Mobile view styles */
  @media (max-width: 767px) {
    /* Adjust styles for mobile view */
    /* For example: */
    width: 90%;
    margin: 0 auto; /* Center the image */
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    /* Adjust styles for laptop view */
    width: 70%;
    margin: 0 auto; /* Center the image */
  }
}
.leftmargin {
  margin: right 100px;
}
</style>
