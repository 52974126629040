<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" v-if="renderComp" />
    <div class="signInCard">
      <div>
        <v-card width="500px">
          <v-toolbar dark dense class="elevation-0 appbar">
            <v-row class="d-flex justify-center">
              <v-col cols="8" md="8">
                <h2 align="center">Sign In</h2>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-row class="d-flex justify-center">
            <v-col cols="12" md="4" sm="4">
              <v-img src="@/assets/NewLogo.png"></v-img>
            </v-col>
          </v-row>
          <v-card-text
            >This OTP is sent to
            {{ this.$store.getters.get_user_email }}</v-card-text
          >
          <v-form>
            <v-otp-input v-model="otp" class="mx-3"></v-otp-input>
          </v-form>
          <v-card-actions class="mx-3">
            <v-btn
              width="100%"
              class="mainbutton white--text"
              @click="login_with_otp"
              :loading="loading"
              >Verify OTP</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  data: () => ({
    otp: "",
    SnackBarComponent: {},
    userDetails: null,
    renderComp: true,
    email: "",
    loading: false,
  }),
  components: {
    Snackbar,
  },
  mounted() {
    this.generate_otp();
  },
  methods: {
    async generate_otp() {
      this.email = this.$store.getters.get_user_email;
      try {
        await Auth.signIn(this.email)
          .then((user) => {
            this.userDetails = user;
            this.showEmail = false;
            this.otpshow = true;
            this.renderComp = false;
            this.$nextTick(() => {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                SnackbarText: `OTP Sent Successfully to ${this.$store.getters.get_user_email}`,
                timeout: 5000,
                Top: true,
              };
              this.renderComp = true;
            });
          })
          .catch((err) => {
            console.log(err);
            this.renderComp = false;
            this.$nextTick(() => {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                SnackbarText: "Could not find your account in RelievingLetter",
                timeout: 5000,
                Top: true,
              };
              this.renderComp = true;
            });
          });
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.message,
          timeout: 5000,
          Top: true,
        };
      }
    },
    async login_with_otp() {
      this.loadingOtp = true;
      try {
        await Auth.sendCustomChallengeAnswer(this.userDetails, this.otp);
        await Auth.currentAuthenticatedUser()
          .then((user) => {
            console.log(user);
            this.$store.commit("SET_USEREMAIL", this.email);
            this.renderComp = false;
            this.$nextTick(() => {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                SnackbarText: "OTP Verified Successfully",
                timeout: 5000,
                Top: true,
              };
              this.renderComp = true;
            });
            this.loadingOtp = true;
            this.$router.push("/LandingPage");
          })
          .catch((error) => {
            console.log(error);
            this.renderComp = false;
            this.$nextTick(() => {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                SnackbarText: "Invalid OTP",
                timeout: 5000,
                Top: true,
              };
              this.renderComp = true;
            });
            this.loadingOtp = false;
          });
      } catch (error) {
        if (error.message == "Incorrect username or password.") {
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Your OTP is still wrong",
              timeout: 5000,
              Top: true,
            };
            this.renderComp = true;
          });
          this.loadingOtp = false;
        } else if (error.message == "Invalid session for the user.") {
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Invalid session for the user",
              timeout: 5000,
              Top: true,
            };
            this.renderComp = true;
          });
          this.loadingOtp = false;
        }
        console.log(error);
      }
    },
  },
};
</script>

<style>
.signInCard {
  display: grid;
  place-items: center;
  height: 80vh;
  margin: 0;
}
</style>
